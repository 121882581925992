@media all and (max-width: $breakpoint-xxlarge) {
  .list-view-settings {
    .quick-filter-border {
      border: none!important;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

@media all and (min-width: $breakpoint-xxlarge) {
  .list-view-settings {
    .quick-filter-border {
      border-right: 1px solid rgb(108, 117, 125, 0.5)!important;
      margin-right: 0.25rem;
      padding-right: 0.25rem;
    }
  }
}

@media all and (max-width: $breakpoint-xlarge) {
    .list-view {
        .list-widget-container {
            margin-left: 1.1em;
            margin-top: 1em;
        }
    }
}

@media all and (max-width: $breakpoint-large) {
    .list-view {
        .list-view-header {
            .container-fluid {
                .row {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .list-view-title {
                margin: 0;
            }
        }
        .list-widget-container {
            margin-top: 1em;
            margin-left: 1.1em;
        }
    }
}


@media all and (max-width: $breakpoint-small) {
    .list-view {
        .pagination-count {
            display: none;
        }
        .chart-sidebar-widget {
            .login-form {
                right: 0.4em;
                width: 95% !important;
                left: 0.4em;
            }
            .chart-widget {
                margin-top: 3rem !important;
            }
        }
        .list-widget-container {
            margin-left: 1.1em;
        }
        .line-action-item {
            padding: 0 0.8em;
        }
    }
    .list-view-header .row {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0!important;
    }
    .table-pagination-wrapper {
        flex-basis: auto;
        display: flex;
        padding-left: 1em!important;
    }
}
