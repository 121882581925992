/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

.column-chooser-container {
  width: 15em;
  max-width: 100%;
  margin: 0 0.75em 1.5em 0.75em;
  display: inline-block;
  vertical-align: top;
}

.column-chooser-list {
  min-height: 3.5em;
  background: $off-white;
  border-radius: 0.25em;
  overflow: hidden;
  display: block;
  padding: 1.5em;
  height: 100%;
}

.column-chooser-item {
  padding: 0.5em 0.8em;
  margin-bottom: 0.5em;
  color: $median-grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  font-size: 0.8em;
  border-radius: 0.25em;
}

.column-hidden {
  background-color: #ffafa4;
}

.column-displayed {
  background-color: #cdc4e3;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.column-chooser-item:last-child {
  border: none;
}

.column-chooser-list.cdk-drop-list-dragging .column-chooser-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.column-chooser-title {
  text-transform: uppercase;
  color: $midnight-grey;
  font-weight: bold;
  font-size: 1em;
  margin-left: 0.5em;
}

.column-chooser-modal .modal-body {
  padding: 0;
}

.column-chooser-modal .modal-lg {
  width: $breakpoint-medium;
}

.cdk-drag {
  z-index: map-get($zindex, modal-mid) !important;
}
