/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

/* --------- COMMON SECTION ---------- */

body {
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background: $off-white;
}

/* <pre> tags shows up in a well separated visible format at a high z-index */
pre {
  font-family: monospace;
  font-size: 1em;
  background: black;
  border: gray;
  color: lightgreen;
  z-index: map-get($zindex, page-low);
}

/* width */
::-webkit-scrollbar {
  width: 0.8em;
}

/* Track */
::-webkit-scrollbar-track {
  background: $dusky-blue;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $midnight-blue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $midnight-blue;
}

.navbar-nav a:not([href]):not([tabindex]) {
  color: $light-grey;
}

.navbar-nav a:not([href]):not([tabindex]):focus,
.navbar-nav a:not([href]):not([tabindex]):hover,
.global-links a:not([href]):not([tabindex]):focus,
.global-links a:not([href]):not([tabindex]):hover {
  color: $white;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
  cursor: pointer;
}

.classic-view-container iframe {
  padding-top: 3em;
  height: 10em;
  width: 100%;
  border: none;
  overflow: hidden;
}

@media only screen and (min-width: $breakpoint-large) {
  .classic-view-container iframe {
    padding-top: 3em
  }
}

