/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */
.light-table {
    .list-view-tableactions {
        background: none;
        padding: 0;
    }
    .list-view-table {
        margin-top: 1em;
        margin-bottom: 1em;
        thead {
          background: none;
        }
        .primary-table-header {
          background: none;
          color: $midnight-blue;
        }
    }
    .bulk-action {
        color: $midnight-blue;
    }
    .pagination-button {
        border: 1px solid $burnt-red;
        margin: 0 0.2em;
        border-radius: .2em;
       .pagination-icons {
           fill: $burnt-red;
           margin: 0.1em;
            svg {
                height: 2em;
                vertical-align: bottom;
            }
       }
    }
    .sort-icon {
        fill: $midnight-blue;
    }
    .pagination-count {
        vertical-align: text-bottom;
        margin: 0.2em 0.3em 0;
    }
    table.list-view-table {
        tr:nth-child(even) {
            background: $pebble-grey;
        }
    }
}
