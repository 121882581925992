

@media all and (max-width: $breakpoint-small) {
  .action-alert.dropdown {

    .notifications {
      .record-thread-item.record-thread-list-item {
        .field-name-name {
          width: 100px;
        }
      }
    }
  }

}



