/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

.footer a {
  font-size: 0.8em;
}

.footer-link {
  color: $midnight-grey;
  cursor: pointer;
  margin: 0 0.5em;
  font-size: 1em;
  svg {
    position: relative;
    top: -0.09em;
  }
}

a.footer-link:not([href]):not([tabindex]) {
  color: $midnight-grey;
}

.footer-link:hover {
  color: $median-grey;
  text-decoration: none;
}

.back-to-top .footer-link {
  text-transform: uppercase;
  font-size: 0.75em;
  vertical-align: middle;
  cursor: pointer;
}

@media all and (min-width: $breakpoint-medium) {
  .footer {
    left: 0;
    bottom: 0;
    padding: 0 1.85em;
    width: 100%;
    height: 3.1em;
    line-height: 3.1em;
    background-color: $light-grey;
    color: $midnight-blue;
  }

  .copyright-links {
    float: left;
    line-height: 3.1em;
  }

  .back-to-top {
    margin: 0 0.5em 0 0;
    padding: 0;
    float: right;
    line-height: 3.1em;
  }
}

@media all and (max-width: $breakpoint-medium) {
  .copyright-links {
    float: left;
    width: 60%;
    padding: 0.5em 0 0 0.25em;
  }

  .back-to-top {
    margin: 0 0.5em 0 0;
    padding: 0;
    float: right;
    line-height: 3.1em;
    width: 35%;
  }

  .back-to-top .footer-link {
    float: right;
    margin: 0;
  }

  .footer {
    left: 0;
    bottom: 0;
    padding: 0;
    width: 100%;
    height: 3.1em;
    background-color: $light-grey;
    color: $midnight-blue;
    margin: 0;
  }

  .footer-link {
    display: block;
    float: left;
  }
}
