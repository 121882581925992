/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

// .record-view {
//   padding-top: 3em;
//   width: 100%;
//   margin: 0;
// }

.record-view {
  padding-top: 3em;
  width: auto;
  margin-left: 4rem;
}

.record-view-position-sticky {
  position: sticky;
  top: 3em;
  z-index: 2;
}

.record-view-header {
  background-color: #f5f5f5;
  border-bottom: 0.03em solid transparent;
  padding-top: 0.5em;
}

.record-view-header-scrolled {
  padding-bottom: 0.3em;
}

.record-view-hr {
  margin-top: 0;
  margin-bottom: 0;
}

.record-view-hr-container {
  font-size: 0.85em;
  padding-top: 0.6em;
  padding-left: 1.6em;
  padding-right: 1.6em;
}

@media all and (min-width: $breakpoint-large) {
  .record-view-hr-container {
    font-size: 0.85em;
    padding: 0.4em 1.6em;
  }
}

.record-view-title {
  color: $shell-grey;
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-left: 1.37rem;
}

.record-view-title.title-font {
  font-size: 1.4em;
}

.record-view-name {
  color: $median-grey;
  font-size: 1.5em;
  margin-left: 1.3rem;
  word-wrap: break-word;
}

.record-view-name-scrolled {
  color: $median-grey;
  font-size: 1.5em;
  margin-left: 1.3rem;
  word-wrap: break-word;
}

.record-view-actions {
  margin: 0;
  text-align: end;
}

.record-view-actions-scrolled {
  margin: 0;
  text-align: end;
}
