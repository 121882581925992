/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2023 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn-group:not(:last-child)>scrm-button .btn,
.btn-group>scrm-button:not(:last-child) .btn:not(.dropdown-toggle),
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn-group:not(:first-child)>scrm-button .btn,
.btn-group>scrm-button:not(:first-child) .btn,
.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>scrm-button .btn.active,
.btn-group-vertical>scrm-button .btn:active,
.btn-group-vertical>scrm-button .btn:focus,
.btn-group>scrm-button .btn.active,
.btn-group>scrm-button .btn:active,
.btn-group>scrm-button .btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
  z-index: 1;
}

.btn-group-vertical>.btn,
.btn-group-vertical>scrm-button .btn,
.btn-group>scrm-button .btn,
.btn-group>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>scrm-button:not(:first-child) .btn,
.btn-group>.btn:not(:first-child) {
  margin-left: -1px;
}
