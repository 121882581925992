@media all and (max-width: $breakpoint-small) {
  .message-container {
    .alert {
      min-width: 22rem;
      overflow: auto;
      max-height: 10em;
    }
  }

  .alert-fixed {
    margin-left: calc(50% - 12rem);
  }

}

@media all and (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
  .message-container {
    .alert {
      min-width: 22rem;
      overflow: auto;
      max-height: 10em;
    }
  }

  .alert-fixed {
    margin-left: calc(50% - 12rem);
  }

}

