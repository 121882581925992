/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

.widget {
  background-color: $white;
}

.widget-panel.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25) !important;
}

.sub-panel .panel-card .card-header {
  background-color: $cool-blue;
}

.widget-panel {
  .minimise-button.btn-outline-light {
    fill: $midnight-grey;
  }

  .minimise-button.btn-outline-light:hover {
    fill: $secondary;
  }
}

.widget-header {
  background-color: $midnight-blue;
  -webkit-border-top-left-radius: 0.3em;
  -webkit-border-top-right-radius: 0.3em;
  -moz-border-radius-topleft: 0.3em;
  -moz-border-radius-topright: 0.3em;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  height: 2.8rem;

  h3 {
    color: $white;
    font-size: 0.875rem;
    margin: 0;
    padding: 0 .75rem;
    line-height: 1.5;
  }
}

.widget-close-icon {
  margin-right: 0.2em;
}

.text-yellow {
  color: $sandy-yellow;
}

.text-blue {
  color: $pale-blue;
}

.text-green {
  color: $wild-willow-green;
}

.text-red {
  color: $burnt-red;
}

.text-purple {
  color: $light-purple;
}

.text-dark {
  color: $black;
}

.text-grey {
  color: $midnight-grey;
}


.text-regular {
}

.text-medium {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.2;
}

.text-large {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.text-x-large {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.text-xx-large {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.text-huge {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.chart-sidebar-widget {
  .chart-loading {
    height: 100px;
  }
}
