/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

/**
 * Override secondary button color and outline secondary button
 */

.btn-outline-secondary {
  color: $secondary;
  border-color: $secondary;
  fill: $secondary;
}

.btn-outline-secondary:hover {
  color: $white;
  fill: $white;
  background-color: $secondary;
  border-color: $secondary;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 164, 179, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: $secondary;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: $white;
  fill: $white;
  background-color: $secondary;
  border-color: $secondary;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 164, 179, 0.5);
}

.btn-secondary {
  color: $white;
  fill: $white;
  background-color: $secondary;
  border-color: $secondary;
}

.btn-secondary:hover {
  color: $white;
  fill: $white;
  background-color: $bright-orange;
  border-color: $bright-orange;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: $white;
  fill: $white;
  background-color: $secondary;
  border-color: $secondary;
  box-shadow: 0 0 0 0.2rem rgba(147, 164, 179, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: $white;
  fill: $white;
  background-color: $secondary;
  border-color: $secondary;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  fill: $white;
  background-color: $bright-orange;
  border-color: $bright-orange;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 164, 179, 0.5);
}
