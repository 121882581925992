// div.fcontainer {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     background-color: #e7e7e7;
//     padding: 20px;
//     border-radius: 8px;
//     font-family: ui-monospace;
//     box-shadow: 0 50px 50px -50px black;
// }

// .lform {
//     margin-right: 20px;
// }

// img{
//     border-radius: 30px;
// }

//  h3 {
//     font-size: 2rem;
//     margin-bottom: 20px;
// }

// input {
//     border-radius: 0.2em;
//     margin-bottom: 15px;
//     outline: none;
//     padding: 5px 10px;
//     border: none;
//     outline: none;
//     width: 100%;
//     border-bottom: 2px solid grey;
// }
// .lform button {
//     background-color: #ea6658;
// }

/*.tbox img{
    width: 300px;
    border-radius: 8px;
}*/

.tbox img {
    width: 95px;
    border-radius: 8px;
  }
  
  .login-box {
      display: flex;
      flex-direction: row;
      top: 50%;
      left: 50%;
      position: fixed;
      transform: translate(-50%,-50%);
      align-items: center;
      background-color: #fff;
      padding: 8px;
      border-radius: 8px;
      margin-top: 8px;
      box-shadow: 0 50px 50px -50px #000;
  }
  
  /*.tbox {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translate(-50%,-25px);
  }*/
  
  .tbox {
    position: absolute;
    top: 40px;
    left: 75%;
    transform: translate(-50%, -25px);
  }
  
  .mbox {
      width: 1px;
      height: 430px;
      background-color: gray;
      margin: 0px 10px;
  }
  
  .detail-box form {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .rbox, .lbox {
      width: 380px;
      height: 430px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
  .rbox input {
      border: none;
      outline: none;
      border-radius: 2px;
      border-bottom: 2px solid gray;
      padding: 5px 15px;
      color: grey;
      margin-bottom: 10px;
      font-size: medium;
      width: 70%;
      font-family: monospace;
      text-align: center;
  }
  
  .rbox button {
      background-color: #eb6758;
      width: 50%;
      height: 30px;
      padding: 0;
  }
  
  .info-box {
      margin-top: 50px;
  }
  
  .slider {
      overflow: hidden;
      width: 300px;
      height: 100%;
      position: relative;
  }
      
     .slider .slide {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 300px;
      height: 100%;
      background-size: cover;
      background-position: center;
      animation: slider 27.5s infinite;
  }
  
  .slider, .slider .slide {
      width: 100%;
      height: 100%;
  }
  
     .slide {
      display: flex;
  }
  
  .slide img {
      object-fit: none;
      padding: 30px;
      width: 100%;
      border-radius: 40px;
  }
  
      
     .slider .slide:nth-child(1) {
        animation-delay: 0s;
  }
     
     .slider .slide:nth-child(2) {
        animation-delay: -5.5s;
  }
     
     .slider .slide:nth-child(3) {
        animation-delay: -11s;
  }
     
     .slider .slide:nth-child(4) {
        animation-delay: -16.5s;
  }
     
     .slider .slide:nth-child(5) {
        animation-delay: -22s;
  }
     
     @keyframes slider {
      0%, 18%, 100% {
        transform: translateX(0);
        animation-timing-function: ease;
      }
      20% {
        transform: translateX(-100%);
        animation-timing-function: step-end;
      }
      98% {
        transform: translateX(100%);
        animation-timing-function: ease;
      }
  }
  
  
  .wsection {
      background-image: url('/legacy/custom/themes/loginimg/bgimg/bgimg3.jpg');
      width: 100%;
      height: 100vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
  
  
  .is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
      display: inline-block;
  }
  
  .message-container .alert {
      min-width: 39rem;
  }
  
  .rbox .col-md-6 {
    flex: 0 0 50%;
    max-width: 100%;
  }
  
  .rbox .inner-addon img{
    height: 55px;
    width: 215px;
    padding-left: 0!important;
  }
  
  .rbox .inner-addon {
    position: relative;
    width: auto!important;
    justify-content: center;
  }