.action-alert.dropdown {

  .dropdown-menu {
    margin-top: -5px;
    padding: 0;
  }

  .badge-position {
    position: absolute;
    top: 2px;
    right: -6px;
    z-index: 1;
  }

  .notifications {

    .notifications-item {
      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 92%;
        border-bottom: 1px solid $lightgray;
        padding-top: 0.2rem;
      }
    }

    .notifications-item.is_read-true {
      background-color: transparent !important;

      .record-thread-item-body {
        background-color: transparent !important;
      }
    }

    .line-actions-button {
      font-size: .775rem;
      color: $midnight-blue;
      margin: 0;
      padding: 0;
    }

    .record-thread-item.record-thread-list-item {
      width: 100%;
      padding: 0;
      background-color: $bright-blue;
      transition: background-color 500ms ease-in-out;
      &:hover {
        background-color: $off-white !important;

        .record-thread-item-body {
          background-color: $off-white !important;
          transition: background-color 500ms ease-in-out;
        }
      }

      .record-thread-item-body {
        background-color: transparent;
        box-shadow: none;
        padding: 0.55rem 1.25rem 0;
        border-radius: 0;
        margin-bottom: 0;
        &:hover {
          background-color: $off-white !important;

          .notification-item-buttons {
            .action-group-menu {
              .inline-confirmation {

                .inline-confirmation-button {
                  background-color: $off-white;
                }

                .inline-confirmation-label {
                  background-color: $off-white;
                }

                .inline-confirmation-label > scrm-label {
                  background-color: $off-white;
                  box-shadow: -.8rem 0.1rem .5rem rgba($off-white, .9);
                }

                .inline-confirmation-label > scrm-dynamic-label {
                  background-color: $off-white;
                  box-shadow: -.8rem 0.1rem .5rem rgba($off-white, .9);
                }
              }
            }
          }
        }

        .notification-item-buttons {
          .action-group-menu {

            .inline-confirmation {

              .inline-confirmation-button button {
                color: $complementary;
              }

            }
          }
        }
      }

      .field-name-name, .field-name-target_module_label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
      }

      .field-name-name {
        width: 168px;
        margin-right: 8px;
      }

      .field-name-target_module_label {
        width: 150px;
      }

      .notifications-actions svg {
        font-size: 75%;
      }

      .field-name-name {

        a, a.clickable {
          color: $black;
        }
      }

      .field-name-target_module_type.field-type-icon svg {
        font-size: 130%;
        fill: $complementary;
        stroke: $complementary;
        margin-top: -0.3rem;
      }

      .notification-item-buttons {
        margin-top: -0.2rem;
      }
    }

    .notification-list-actions button {
      color: $complementary;
    }

    .record-thread-load-more .load-more-button {
      font-weight: 400;
    }

    .record-thread-load-more::after {
      content: "|";
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      color: $complementary;
    }

    .notification-list-actions {
      .action-group-menu {
        .inline-confirmation-label {
          color: $dim-grey;
        }
      }
    }

  }

}


