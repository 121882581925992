/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

@media all and (max-width: $breakpoint-xsmall) {
  .modal-header,
  .modal-footer {
    background-color: $white;
  }
  .modal-body {
    margin: 0;
    overflow-y: scroll;
    max-height: $breakpoint-xsmall;
  }
}

@media all and (min-width: $breakpoint-xsmall) and (max-width: $breakpoint-xlarge) {
  .modal-header,
  .modal-footer {
    background-color: $white;
  }
  .modal-redirect-text {
    color: $white;
    font-weight: bold;
    font-size: 0.8em;
    margin: 0 0 0 2.5em;
  }
  .modal-body {
    margin: 0;
    overflow-y: scroll;
    max-height: $breakpoint-medium;
  }
  .modal-options {
    margin: 0 0 0.5em 0;
  }
}

.modal-title {
  text-align: center;
  margin: 0 auto;
  padding: 0;
  line-height: 0.9em;
}

.modal-content {
  border: none;
  background-color: $white;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}

@media all and (min-width: $breakpoint-xlarge) {
  .modal-body {
    margin: 1em;
  }

  .modal-redirect-text {
    color: $white;
    font-weight: bold;
    font-size: 0.8em;
    margin: 1em 0 0 2em;
  }

  .modal-header,
  .modal-footer {
    background-color: $white;
    max-height: 2.7rem;
  }

  .modal-header {
    .close-button {
      border: none;
      color: $cool-blue;
      font-size: 2em;
      font-weight: normal;
      margin: 0;
      padding: 0;
      width: auto;
      &:hover {
        background: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  .modal-options {
    float: left;
    text-align: right;
  }

  .modal-buttons {
    float: right;
    text-align: right;
  }
}

.modal-field-header {
  background-color: $pebble-grey;
  padding: 0.6em 0.7em;
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  border-radius: 0.25em;
  color: $midnight-blue;
  font-size: 1em;
  letter-spacing: 0.08em;
}

.modal-backdrop {
  background-color: $light-grey;
  z-index: 1050!important;
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modal-header .close {
  margin: -0.3em 0;
  padding: 0;
  opacity: 1;
}

.relate-input-group {
  display: inline;
}

.modal-redirect-text {
  color: $white;
  font-weight: bold;
  font-size: 0.8em;
}

/* width */
.modal-body::-webkit-scrollbar {
  width: 0.8em;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
  background: $light-grey;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
  background: $dusty-grey;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
  background: $dusty-grey;
}

.modal-dialog-scrollable .modal-body {
  max-height: calc(100vh - 6.5rem);
}

/* modal animation */
.modal-content {
  animation-name: modal;
  animation-duration: 0.5s;
}

@keyframes modal {
  0% {
    transform: scale(0.5)
  }
  100% {
    transform: scale(1)
  }
}

.modal-footer {
  padding: 0;
}
