@media all and (max-width: $breakpoint-xlarge) {
    .record-view-container {
        .no-gutters {
            padding-bottom: 0!important;
        }
        .sub-panel div.card {
            margin-bottom: 0.625rem;
        }
    }
    div.record-widget-container {
        padding-left: 1.2em !important;
    }    
}

@media all and (max-width: $breakpoint-small) {
    .record-view-container {
        .tabs-layout {
            .nav-tabs {
                .nav-item {
                    margin-bottom: 1px;
                }
                .tab-link {
                    padding-left: 0.5em;
                    padding-right: 0.5em;
                }
            }
        }
        .no-gutters {
            padding-bottom: 0!important;
        }
    }
    .view-container {
        padding-left: 1em;
        padding-right: 1em;
    }    
    .field-datetime-edit {
        margin: 0;
        max-width: 100%;
    }
}