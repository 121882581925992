.asteriskintegration_administration_head_title {
    width: 100%;
}

.asteriskintegration_administration_head_title_text {
    width: 100%;
}

.asteriskintegration_administration_head_title_text a {
    text-decoration: none;
}

.asteriskintegration_administration_head_title_text span::before {
    content: "» ";
}

.asteriskintegration_administration_table_title {
    clear: both;
    background: #66727D;
    color: #FFFFFF;
    height: 32px;
    text-transform: uppercase;
    line-height: 32px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 14px;
    padding: 0px 0px 0px 20px;
}

.asteriskintegration_administration_table_title:last-child {
    margin-top: 30px;
}

.asteriskintegration_administration_list_column_title {
    background-color: #778591;
    height: 32px;
    line-height: 32px;
    color: #FFFFFF;
    font-weight: bold;
    padding: 0px 0px 0px 20px;
}

.asteriskintegration_administration_list_column_title span {
    display: inline-block;
    text-align: center;
}

.asteriskintegration_administration_list_rows {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.asteriskintegration_administration_list_row {
    height: 32px;
    line-height: 32px;
    padding: 0px 0px 0px 20px;
}

.asteriskintegration_administration_list_row:nth-child(even) {
    background-color: #F5F5F5;
}

.asteriskintegration_administration_list_row:nth-child(even) {
    background-color: #FFFFFF;
}

.asteriskintegration_administration_list_row:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.asteriskintegration_administration_list_row span {
    display: inline-block;
    text-align: center;
}

.asteriskintegration_administration_list_no_records {
    background-color: #FFFFFF;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.asteriskintegration_administration_configuration_form {
    line-height: 32px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.asteriskintegration_administration_configuration_form_row {
    background-color: #FFFFFF;
}

.asteriskintegration_administration_configuration_form_row span {
    display: inline-block;
    padding: 0px 0px 0px 20px;
}

.asteriskintegration_administration_configuration_form_row span:nth-child(1) {
    background-color: #F5F5F5;
    font-weight: bold;
    width: 25%;
}

.asteriskintegration_administration_configuration_form_row span:nth-child(2) {
    width: 30%;
}

.asteriskintegration_administration_configuration_form_row span:nth-child(3) {
    color: #FF0000;
    width: 40%;
}

.asteriskintegration_administration_configuration_form_row span:nth-child(1)::after {
    content: ": ";
}

.asteriskintegration_administration_configuration_form_row:last-child span:nth-child(1)::after {
    content: "";
}

.asteriskintegration_administration_configuration_form_row input[type=text] {
    padding: 2px 2px 2px 5px;
}

.asteriskintegration_administration_configuration_form_row input[type=submit] {
    width: 72px;
}

.asteriskintegration_call_notification_popup {
    bottom: 0px;
    right: 0px;
    display: block;
    position: fixed;
    z-index: 1;
}

.asteriskintegration_call_notification_titlebar {
    background-image: url('images/asterisk.png');
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 10px;
    background-color: #FF0000;
    background-size: 19px 16px;
    padding: 8px 10px 8px 32px;
    font-weight: bold;
    color: #FFFFFF;
    height: 35px;
}

.asteriskintegration_call_notification_maximized {
    background-image: url('images/minimize.png');
}

.asteriskintegration_call_notification_minimized {
    background-image: url('images/maximize.png');
}

.asteriskintegration_call_notification_maximized,
.asteriskintegration_call_notification_minimized {
    background-repeat: no-repeat;
    background-size: 16px 16px;
    outline: 0;
    float: right;
    width: 16px;
    height: 16px;
    margin-top: 2px;
}

.asteriskintegration_call_notification_container {
    max-height: 453px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-left: 1px solid #404040;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}

.asteriskintegration_call_notification_container::-webkit-scrollbar {
    width: 4px;
}

.asteriskintegration_call_notification_container::-webkit-scrollbar-track {
    border-radius: 0px;
}

.asteriskintegration_call_notification_container::-webkit-scrollbar-thumb {
    background-color: #404040;
    border-radius: 0px;
}

.asteriskintegration_popupbox {
    border-top: 1px solid #d4d7d9;
    border-left: 1px solid #d4d7d9;
    border-right: 1px solid #d4d7d9;
    border-bottom: 2px solid #d4d7d9;
    margin: 5px;
}

.asteriskintegration_popupbox_head {
    background-color: #404040;
    height: 68px;
}

.asteriskintegration_popupbox_head_inbound {
    background-image: url('images/inbound.png');
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: 10px;
    background-size: 48px 48px;
    outline: 0;
}

.asteriskintegration_popupbox_head_outbound {
    background-image: url('images/outbound.png');
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: 10px;
    background-size: 48px 48px;
    outline: 0;
}

.asteriskintegration_popupbox_head_info {
    float: left;
    margin-left: 68px;
    margin-top: 10px;
    max-width: calc(100% - 126px);
    width: calc(100% - 126px);
}

.asteriskintegration_popupbox_head_info_record_name {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
}

.asteriskintegration_popupbox_head_info_parent_name {
    color: #FFFFFF;
    font-size: 12px;
    padding-bottom: 5px;
}

.asteriskintegration_popupbox_head_info_record_name > a,
.asteriskintegration_popupbox_head_info_parent_name > a {
    text-decoration: none;
    color: #FFFFFF;
}

.asteriskintegration_popupbox_head_buttons {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
}

.asteriskintegration_popupbox_head_options_button {
    background-image: url('images/options.png');
    background-repeat: no-repeat;
    background-size: 22px 22px;
    float: right;
    height: 22px;
    width: 22px;
    margin-right: 4px;
}

.asteriskintegration_popupbox_head_close_button {
    background-image: url('images/close.png');
    background-repeat: no-repeat;
    background-size: 22px 22px;
    float: right;
    height: 22px;
    width: 22px;
}

.asteriskintegration_popupbox_head_transfer_button {
    background-image: url('images/transfer.png');
    background-repeat: no-repeat;
    background-size: 22px 22px;
    float: right;
    height: 22px;
    width: 22px;
    margin-right: 4px;
    margin-top: 4px;
}

.asteriskintegration_popupbox_head_hangup_button {
    background-image: url('images/hangup.png');
    background-repeat: no-repeat;
    background-size: 22px 22px;
    float: right;
    height: 22px;
    width: 22px;
    margin-top: 4px;
}

.asteriskintegration_popupbox_options {
    background-color: #74818D;
    border-top: 1px solid #d4d7d9;
}

.asteriskintegration_popupbox_options ul {
    padding: 2px 0px 2px 0px;
}

.asteriskintegration_popupbox_options ul li {
    color: #FFFFFF;
    padding: 4px 0px 4px 10px;
}

.asteriskintegration_popupbox_options ul li:hover {
    background-color: #d4d7d9;
    color: #000000;
    font-weight: bold;
}

.asteriskintegration_popupbox_body {
    background-color: #FFFFFF;
    padding: 5px 0px 5px 10px;
}

.asteriskintegration_popupbox_body > ul {
    padding-left: 15px;
}

.asteriskintegration_popupbox_body ul li {
    padding: 5px 0px 5px 0px;
    list-style-type: none;
}

.asteriskintegration_popupbox_body ul li label {
    margin: 0px;
    font-size: 11px;
    font-weight: normal;
    width: 75px;
    text-align: right;
    vertical-align: text-bottom;
    padding-right: 10px;
    color: #74818D;
}

.asteriskintegration_popupbox_body ul li label::after {
    content: ":"
}

.asteriskintegration_popupbox_body ul li value {
    margin: 0px;
    font-size: 13px;
    font-weight: bold;
    width: calc(100% - 85px);
    text-align: left;
}

.asteriskintegration_popupbox_call_description {
    width: 100%;
    background-color: #F7F7F7;
}

.asteriskintegration_popupbox_call_description_textarea {
    width: calc(100% - 35px);
    border: none;
    background-color: #F7F7F7;
    resize: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    overflow-y: hidden;
    word-wrap: break-word;
}

.asteriskintegration_popupbox_call_description_textarea:focus {
    outline: none;
}

.asteriskintegration_popupbox_call_description_textarea::placeholder {
    font-style: italic;
    font-size: 13px;
}

.asteriskintegration_popupbox_save_icon {
    float: right;
    position: relative;
    top: 11px;
    right: 11px;
    display: none;
}

.asteriskintegration_popupbox_save_icon img {
    width: 24px;
    height: 24px;
}
