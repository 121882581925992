/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

.view-container {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.small-font {
  font-size: 0.85em;
}

.full-height-view {
  min-height: calc(100vh - 3.2em);

  .full-height-view-row {

    @media all and (min-width: $breakpoint-xsmall) {
      max-width: 24em;

      .logo img {
        max-width: 22em;
      }
    }

    @media all and (max-width: $breakpoint-medium) {
      .logo img {
        max-width: 20em;
      }
    }

    @media all and (max-width: $breakpoint-xsmall) {
      .logo img {
        max-width: 20em;
      }
    }

    img {
      text-align: center;
      max-width: 22em;
    }
  }
}

.page-title {
  color: $shell-grey;
  font-size: 1.8rem;
  font-weight: 300;
  margin: 1em 0.7em;
  letter-spacing: .1em;
  text-align: center;

  @media all and (min-width: $breakpoint-xsmall) {
    h2 {
      font-size: 2rem;
    }
  }

  @media all and (max-width: $breakpoint-medium) {
    h2 {
      font-size: 1.4rem;
    }
  }

  @media all and (max-width: $breakpoint-xsmall) {
    h2 {
      font-size: 1.4rem;
    }
  }

}

.page-subtitle {
  color: $shell-grey;
  text-align: center;

  @media all and (min-width: $breakpoint-xsmall) {
    h5 {
      font-size: 1.25rem;
    }
  }

  @media all and (max-width: $breakpoint-medium) {
    h5 {
      font-size: 1.1rem;
    }
  }

  @media all and (max-width: $breakpoint-xsmall) {
    h5 {
      font-size: 1.1rem;
    }
  }

}
