/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

/* --------- NAVBAR SECTION ---------- */

.navbar {
  height: 3.1em;
  background-color: $midnight-blue;
  margin: 0;
  padding: 0 1em;

  .navbar-action-group {
    .action-link svg,
    .action-link svg path,
    .action-link svg polygon {
      fill: rgba(255, 255, 255, 0.8);
    }

    .global-action-icon svg,
    .global-action-icon svg path,
    .global-action-icon svg polygon {
      fill: rgba(255, 255, 255, 0.8);
    }

    .action-link:hover svg,
    .action-link:hover svg path,
    .action-link:hover svg polygon {
      fill: $salmon-pink;
    }

    .global-action-icon:hover svg,
    .global-action-icon:hover svg path,
    .global-action-icon:hover svg polygon {
      fill: $salmon-pink;
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .navbar-nav {
    .main-grouped:hover .dropdown-menu.main,
    .global-link-item:hover .dropdown-menu,
    .non-grouped:hover .dropdown-menu {
      display: block;
      right: 0;
    }
  }

  .nav-link-grouped,
  .nav-link-nongrouped {
    color: $off-white;
    line-height: 3.1em;
    padding: 0.5em 0.5em 1.1em 0.5em;
  }

  .nav-link-grouped:active,
  .nav-link-nongrouped:active {
    color: $light-grey;
    border-left: 0.2em solid $salmon-pink;
    padding: 0.8em 0.5em 1em 0.5em;
    text-decoration: none;
  }

  .nav-link-grouped a:hover,
  .nav-link-nongrouped a:hover {
    color: $light-grey;
    text-decoration: none;
  }
}

.home-nav {
  line-height: 3.1em;
}

a.home-nav-link {
  color: $white;
  line-height: 3.1em;
  padding: 0.8em 1em 1em 1em;
}

.nav-item.active .home-nav-link svg {
  fill: $salmon-pink;
}

.recent-link scrm-svg-icon-ui > svg {
  margin: 0.3em 0.8em 0.8em 0;
}

.recent-link {
  display: inline;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: $light-grey;
}

.nav-link:hover {
  color: $off-white;
}

.nav-item.active .nav-link-nongrouped,
.nav-item.active .home-nav-link {
  color: $salmon-pink;
  border-left: 0.2em solid $salmon-pink;
  // padding: 0.8em 1em 1.1em 1em;
  padding: 0 0.4em!important;
  text-decoration: none;
}

.global-link-item:hover {
  color: $salmon-pink;
}

.submenu,
.globalactions {
  background-color: $dusky-blue;
  color: $light-grey;
  margin: 0;
  border: 0;
}

.submenu li {
  border: 0;
  padding: 0 0.3em;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.nav-menu-icon {
  width: 1.5em;
  height: 1.5em;
}

.action-btn-icon {
  width: 1.2em;
  height: 1.2em;
}

h4.recently-viewed-header,
h4.favorite-header {
  border-top: 0.05em solid darken($light-grey, 20%);
  color: darken($light-grey, 20%);
  font-weight: bold;
  margin: 0.8em 0 0 0;
  padding: 0.8em;
  font-size: 0.9em;
}

.nav-link:hover svg path,
.nav-link:hover svg polygon {
  fill: $salmon-pink;
}

.navbar-1 {
  float: left;
  width: 100%;
}

.navbar-2 {
  float: right;
  padding: 0;
}

.global-action-icon {
  width: 1.5em;
  height: 1.5em;
  margin: 0;
}

@media only screen and (max-width: $breakpoint-medium) {
  .global-action-icon {
    margin: 0;
  }
}

@media only screen and (max-width: $breakpoint-large) {
  .navbar-1,
  .navbar-2 {
    padding: 0;
    margin: 0;
    float: left;
    top: 0;
    z-index: map-get($zindex, nav-high);
  }

  .top-nav .dropdown-toggle::after {
    display: none;
  }

  .navbar-header {
    padding: 0 0.8em;
  }

  .navbar-nav li {
    padding: 0 1.6em;
  }

  .nav-link {
    font-size: 0.85em;
    color: $white;
  }

  .nav-link-grouped,
  .nav-link-nongrouped {
    color: $off-white;
    line-height: 3.1em;
    padding: 0.8em 0.5em 1.1em 0.5em;
    font-size: 0.85em;
  }

  .nav-link-grouped:hover,
  .nav-link-nongrouped:hover {
    color: $light-grey;
    text-decoration: none;
  }
  .navbar-nav,
  .navbar-nav li {
    border-bottom: 0.05em solid $off-white;
  }

  .navbar-nav:last-child,
  .navbar-nav li:last-child {
    border: none;
  }

  .top-nav.nav-item:hover {
    padding: 0 1.6em;
  }

  .global-action-icon svg {
    width: 1.5em;
    height: 1.5em;
    margin: 0 0.6em 0 0;
    fill: $white;
  }

  .global-links .nav-link {
    font-size: 1em;
    font-weight: bold;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .navbar-nav > li > .dropdown-menu,
  .collapsenav .navbar-nav > li {
    background-color: $dusky-blue;
  }
}

.navbar-toggler:hover svg path {
  stroke: $salmon-pink;
}

.navbar {
  scrm-logo-ui img {
    margin: 0.3em;
    padding-left: 0.5em;
    height: 2.4em;
  }

  .action-group {
    display: flex;
    flex-direction: row;

    .action-link {
      padding: 0 0.6rem;

      svg {
        margin: 0;
      }
    }
  }
}

a.nav-link.dropdown-toggle::after,
a.nav-link-grouped.dropdown-toggle::after,
a.nav-link-nongrouped.dropdown-toggle::after {
  vertical-align: middle;
}

.primary-global-link.dropdown-toggle::after {
  display: none;
}

.mobilenavbar {
  list-style: none;
  padding: 0;
  background-color: $midnight-blue;

  .navbar-toggler.dropdown-toggle::after {
    display: none;
  }
}

.mobile-nav-block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.mobile-nav-dropdown {
  max-width: 20rem;
}

.mobile-nav-link {
  font-size: 0.85em;
  color: $white;
}

.mobile-nav-link:hover {
  color: $white;
  background-color: none;
}

.mobile-back-link {
  font-size: 0.85em;
  color: $white;
  font-weight: bold;
}

.mobile-nav-close {
  color: $nepal-grey;
  font-weight: bold;
}

.nav-close-text {
  font-size: 0.8em;
  margin-left: 0.2em;
}

.mobile-nav-close:hover {
  color: $white;
  text-decoration: none;
}

.mobile-nav-close:hover svg polygon {
  fill: $white;
}

.nav-collapsed-items {
  li {
    a.dropdown-toggle {
      &::after {
        margin-left: 0.5em;
      }
    }
  }
}
