/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

/* --------- DROPDOWN SECTION ---------- */

.dropdown-menu {
  background-color: $dusky-blue;
  width: max-content;
  min-width: 9em;
}

.dropdown-menu.submenu {
  border-radius: 0;
}

ngb-datepicker.dropdown-menu {
  padding: 0;
  background-color: $white;
  z-index: map-get($zindex, nav-max);
}

ngb-datepicker-navigation {
  padding: 0.1em;
}

.ngb-dp-weekdays {
  background-color: $nepal-grey;
}

.ngb-dp-weekday {
  color: $white;
}

.btn-light {
  color: $coral-pink
}

.action-group .dropdown-menu {
  background-color: $white;
  margin-top: 0;
  font-size: 0.8em;
}

.action-group h4 {
  font-size: 0.8em;
  color: $midnight-grey;
}

.action-group .dropdown-menu li {
  padding: 0.5em;
}

.action-group .dropdown-menu li a {
  color: $median-grey;
}

.action-group .dropdown-menu li a:hover {
  text-decoration: none;
}

.action-alert .dropdown-menu li:not(:last-of-type),
.action-favourite .dropdown-menu li:not(:last-of-type), {
  border-bottom: 0.05em dotted $midnight-grey;
}

.action-favourite .dropdown-menu svg path {
  fill: $sandy-yellow;
}

.action-new .dropdown-menu svg polygon {
  fill: $complementary;
}

.action-new .dropdown-menu {
  margin-top: -5px;
  width: 240px;

  .new-list-item {
    padding-left: 2rem;

    span {
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }

  }

  .new-list-item-header:first-child {
    border-top: none;
    padding-top: 0.36rem;
  }

  .new-list-item-header:last-child {
    padding-top: 0.6rem;
    padding-bottom: 0.2rem;
    margin-top: 0.2rem;
  }

  .new-list-item-header {
    padding-left: 1.44rem;
    padding-bottom: 0;
    border-top: 1px solid $lightgray;

    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 1.125em;
    color: $complementary;
  }

  .new-list-item:hover {
    background-color: $off-white;
    cursor: pointer;
  }
}

.alert-list-item:hover {
  background-color: $bright-grey;
  cursor: pointer;
}

.favourite-list-item:hover {
  background-color: $sky-grey;
  cursor: pointer;
}

.action-favourite .dropdown-menu {
  border: 0.35em solid $sandy-yellow;
}

.action-new .dropdown-menu .action-btn-icon {
  display: inline-block;
  height: auto;
  width: auto;
}

.alerts-button.dropdown-toggle[_ngcontent-c1]::after,
.quickcreate-button.dropdown-toggle[_ngcontent-c1]::after,
.favourites-button.dropdown-toggle[_ngcontent-c1]::after {
  vertical-align: 0.05em;
}

a.close-navmenu {
  color: $light-grey;
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
}

a.close-navmenu:hover {
  color: $salmon-pink;
}

.close-navmenu svg polygon {
  fill: $light-grey;
}

.close-navmenu svg {
  margin: 0 0.3em 0 0;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 5%;
  top: 40%;
}

.dropdown-submenu:hover .dropdown-menu,
.dropdown-submenu:focus .dropdown-menu,
.navbar-nav .non-grouped:hover .dropdown-submenu.submenu:hover .dropdown-menu.submenu,
.navbar-nav .non-grouped:hover .dropdown-submenu.submenu:focus .dropdown-menu.submenu,
.dropdown-submenu.submenu .dropdown-submenu.submenu.submenu-extra:hover .dropdown-menu.submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
}

.navbar-nav .non-grouped:hover .dropdown-submenu.submenu .dropdown-menu.submenu,
.dropdown-submenu.submenu .dropdown-submenu.submenu.submenu-extra .dropdown-menu.submenu {
  display: none;
}

.dropdown-submenu:hover .dropdown-menu a,
.dropdown-submenu:focus .dropdown-menu a,
.navbar-nav .non-grouped:hover .dropdown-submenu.submenu:hover .dropdown-menu.submenu a,
.navbar-nav .non-grouped:hover .dropdown-submenu.submenu:focus .dropdown-menu.submenu a {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-nav .dropdown-menu .nav-item:hover .action-link:hover svg,
.navbar-nav .dropdown-menu .nav-item:hover .action-link:hover svg path,
.navbar-nav .dropdown-menu .nav-item:hover .action-link:hover svg polygon,
.navbar-nav .dropdown-menu .nav-item:hover .action-link svg,
.navbar-nav .dropdown-menu .nav-item:hover .action-link svg path,
.navbar-nav .dropdown-menu .nav-item:hover .action-link svg polygon {
  fill: $white;
}

.navbar-nav .dropdown-menu .nav-item:hover a.action-link:hover,
.navbar-nav .dropdown-menu .nav-item:hover a.action-link {
  color: $white;
}

.nav-link-grouped:hover, .nav-link-nongrouped:hover {
  color: $light-grey;
  text-decoration: none;
  background-color: $dusky-blue;
  margin-top: 1em;
  -webkit-border-top-left-radius: 0.285em;
  -webkit-border-top-right-radius: 0.285em;
  -moz-border-radius-topleft: 0.285em;
  -moz-border-radius-topright: 0.285em;
  border-top-left-radius: 0.285em;
  border-top-right-radius: 0.285em;
}

ul.main li.nav-item,
.main ul li {
  padding: 0 1em;
}

.nav-item .dropdown-menu {
  border: 0;
}

.dropdown-menu .nav-item:hover, .dropdown-menu .nav-item .action-link:hover {
  background-color: $dull-orange;
}

ul.main li a,
.main ul li a,
.non-grouped .action-link {
  font-size: 0.8em;
  padding: 0.7rem;
}

.top-nav .dropdown-menu,
.top-nav .submenu {
  margin: 0;
  padding: 0;
}

.global-links-dropdown {
  margin: 0;
  margin-top: -5px;
  right: 0;
  position: absolute;
  width: 240px;
  background-color: $white;

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .global-user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 0.4em 0.8em 0 1.5em;
    font-size: .9em;
    color: $complementary;
    font-weight: bold;
  }
}

.primary-global-link {
  display: inherit;
  height: 3.1em;
  line-height: 3.1em;

  &:hover {
    background: $dusky-blue;
  }

  &::after {
    margin-top: -5px;
  }
}

.global-links a.global-links-sublink:not([href]):not([tabindex]) {
  color: inherit;
}

.global-links a.global-links-sublink:hover:not([href]):not([tabindex]) {
  color: inherit;
}

.global-links svg path {
  fill: rgba(255, 255, 255, 0.8);
}

.global-link-item .nav-link {
  height: 3.1em;
  line-height: 3.1em;
  margin: 0;
  padding: 0;
}

.select-action-group {
  .bulk-action-button {
    min-width: auto;
  }
}

@media only screen and (min-width: $breakpoint-large) {

  .global-links-dropdown
  .global-links-sublink {
    padding: 0.4em 0.8em 0.4em 1.8em;
    font-size: 0.8em;
    border-bottom: none;
  }

  .global-links-dropdown a:hover {
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
    -o-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;
    background: $off-white;
  }
}

@media only screen and (max-width: $breakpoint-large) {
  .global-links .dropdown-menu {
    border: none;
    left: -12.7em;
  }

  .global-links a {
    border: none;
    padding: 0.4em 0.8em 0.4em 1.8em;
    font-size: 0.9em;
  }
}


@media (max-width: $breakpoint-xlarge) {
  .dropdown-menu .dropdown-submenu {
    width: auto;
  }
}

@media all and (max-height: $breakpoint-xxxlarge) {
  .more-menu {
    height: max-content;
    max-height: $breakpoint-xxlarge;
    overflow-y: scroll;
  }
}

@media all and (max-height: $breakpoint-xxlarge) {
  .more-menu {
    height: max-content;
    max-height: $breakpoint-large;
    overflow-y: scroll;
  }
}

@media all and (max-height: $breakpoint-xlarge) {
  .more-menu {
    height: max-content;
    max-height: $breakpoint-medium;
    overflow-y: scroll;
  }
}

@media all and (max-height: $breakpoint-large) {
  .more-menu {
    height: max-content;
    max-height: $breakpoint-small;
    overflow-y: scroll;
  }
}

@media all and (max-height: $breakpoint-small) {
  .more-menu {
    height: max-content;
    max-height: 15em;
    overflow-y: scroll;
  }
}

.chart-item, .dropdown-item.active, .dropdown-item:active {
  background-color: transparent;
}

.dropdown-button .dropdown-menu,
.button-group-dropdown .dropdown-menu,
.bulk-action-group .dropdown-menu,
.select-action-group .dropdown-menu {
  font-size: 1em;
  -webkit-border-radius: 0.25em;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 0.25em;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-radius: 0.25em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: $cool-blue;
  width: max-content;
  min-width: 10em;
  border: 0;
  margin: 0;
  padding-bottom: 0.1em;
  padding-top: 0;
  .dropdown-item {
    padding: .25em .8em;
  }
}

.dropdown-button-secondary .dropdown-menu {
  background-color: $nepal-grey;
}

.select-action-group .dropdown-menu {
  font-size: 1em;
  -webkit-border-radius: 0.25em;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 0.25em;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-radius: 0.25em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: $nepal-grey;
  width: max-content;
  min-width: 10em;
  border: 0;
  margin: 0;

}

.sectioned-dropdown-menu .dropdown-menu {
  font-size: 0.8em;
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  border-radius: 0.25em;
  background-color: $white;
  color: $median-grey;
  min-width: 10em;
  border: 1px solid $extra-light-grey;
  margin: 0;
  width: 240px;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);

  .dropdown-menu-item-header {
    padding: 0.5rem 1.44rem 0 1.44rem;
    border-top: 1px solid $lightgray;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 1.125em;
    font-weight: bold;
    color: $complementary;

    &:first-child {
      border-top: none;
    }
  }

  .section-split {
    border-top: 1px solid $lightgray;
  }

  .last-section .dropdown-menu .dropdown-item {
    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  .first-section {
    padding-top: 4px;
  }

}

.filter-select .dropdown-menu,
.sectioned-dropdown-menu .dropdown-menu,
.select-action-group .dropdown-menu {

  .dropdown-item {
    padding: .25em .8em;

    svg {
      margin: 0 0.5em 0 0;
    }
  }

  .dropdown-item.active {
    padding-left: 1em;
    font-weight: bold;

    svg {
      fill: $black;
      stroke: $black;
    }
  }
}

.dropdown-button-secondary .dropdown-menu a,
.select-action-group .dropdown-menu a {
  color: $white;
}

.dropdown-button-secondary .dropdown-menu a:hover,
.select-action-group .dropdown-menu a:hover {
  background-color: $astral-blue;
  color: $white;
  cursor: pointer;
}

.dropdown-button .dropdown-menu a,
.button-group-dropdown .dropdown-menu a,
.bulk-action-group .dropdown-menu a,
.select-action-group .dropdown-menu a {
  color: $white;
}

.dropdown-button .dropdown-menu a:hover,
.button-group-dropdown .dropdown-menu a:hover,
.bulk-action-group .dropdown-menu a:hover,
.select-action-group .dropdown-menu a:hover {
  background-color: $astral-blue;
  color: $white;
  cursor: pointer;
}

.sectioned-dropdown-menu .dropdown-menu .dropdown-item {
  color: $median-grey;
  padding: 0.5rem 0 0.5rem 2rem;

  .dropdown-item-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }

  &:hover {
    background-color: $off-white;
    color: $black;
    cursor: pointer;
  }
}

.mobile-nav-dropdown.dropdown-menu {
  padding: 0;
  margin: 0;
}

.mobile-nav-dropdown li, .mobile-nav-dropdown li:hover {
  color: $off-white;
  margin: 0;
  border-top: 0.05em solid $off-white;
}

.mobile-nav-dropdown li a {
  display: inline-block;
}

.mobile-nav-dropdown .mobile-nav-arrow {
  float: right;
}

.mobile-nav-dropdown .dropdown-item:hover {
  background-color: $vacant-orange;
  cursor: pointer;
}
