/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */
/* Light filter SCSS */

.light-filter {
    div.card-header {
        background: none;
        border-bottom: 2px dotted $very-light-grey;
        padding: 0 0 0.8rem 0;
        .panel-title {
            color: $midnight-blue;
            font-weight: bold;
        }
    }
    div.card-body {
      padding: 1.25rem 0 0 0 !important;

      .filter-body {
        padding: 0 !important;

        form {
          color: $midnight-blue;
        }
      }
    }
    .minimise-button {
        font-size: 0.6rem;
        fill: $coral-pink;
        border: 1px solid $coral-pink;
        border-radius: 0.2rem;
    }
}
