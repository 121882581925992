/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

/* --------- NAVBAR SECTION ---------- */

.mobile-back-row {
  border-bottom: .05em solid $white;
  border-top: 0;
}

.mobile-menu-items {
  max-height: calc(40vh);
  overflow-y: auto;
}

.card.mobile-nav-group-item {
  background: none;
  padding: 0;

  .card-header {
    padding: 0;
    background: none;
    color: $white;
    border: 0;
    border-top: .05em solid $white;

    button {
      font-size: .85em;
      padding: .5rem 2.7rem;
      margin: 0;
      text-decoration: none;
      width: 100%;
      text-align: left;
      color: $white;
    }
  }

  .card-body {

  }
}
