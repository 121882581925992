/**
 * SuiteCRM is a customer relationship management program developed by SalesAgility Ltd.
 * Copyright (C) 2021 SalesAgility Ltd.
 *
 * This program is free software; you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License version 3 as published by the
 * Free Software Foundation with the addition of the following permission added
 * to Section 15 as permitted in Section 7(a): FOR ANY PART OF THE COVERED WORK
 * IN WHICH THE COPYRIGHT IS OWNED BY SALESAGILITY, SALESAGILITY DISCLAIMS THE
 * WARRANTY OF NON INFRINGEMENT OF THIRD PARTY RIGHTS.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 *
 * In accordance with Section 7(b) of the GNU Affero General Public License
 * version 3, these Appropriate Legal Notices must retain the display of the
 * "Supercharged by SuiteCRM" logo. If the display of the logos is not reasonably
 * feasible for technical reasons, the Appropriate Legal Notices must display
 * the words "Supercharged by SuiteCRM".
 */

// .list-view {
//   padding-top: 3em;
//   width: 100%;
//   margin: 0;
// }

.list-view {  
  padding-top: 3em;
  // width: 100%;
  margin: 0;
  width: auto;
  margin-left: 4rem;
}

.list-view-header {
  border-bottom: 0.03em solid transparent;
  padding-top: 0.5em;
}

.list-view-title {
  color: $shell-grey;
  font-size: 1.8em;
  font-weight: 300;
  margin: 0 0.7em;
  letter-spacing: 0.1em;
  line-height: normal;
  text-transform: uppercase;
}

.list-view-actions {
  margin: 1em 0 0 0;
  text-align: end;
}

.list-view-settings {
  text-align: end;
}

.scrm-settings-menu {
  display: flex;
  width: 100%;
  justify-content: end;
}

.load-more-style {
  align-content: center;
}

.list-view-container {
  font-size: 0.85em;
  margin-bottom: 4em;
}

.list-view-hr {
  margin-top: 0;
  margin-bottom: 0;
}

.list-view-widget {
  margin-right: 1.5em;
}

.list-widget-container {
  padding-left: 0.6em;
}

.list-view-table {
  margin: 0;
  table-layout: fixed;
}

.striped-table {
  tbody tr:nth-of-type(odd) {
    background-color: white;
  }
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-header {
  -webkit-border-top-left-radius: 0.3em;
  -webkit-border-top-right-radius: 0.3em;
  -moz-border-radius-topleft: 0.3em;
  -moz-border-radius-topright: 0.3em;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

.table-footer {
  -webkit-border-bottom-right-radius: 0.3em;
  -webkit-border-bottom-left-radius: 0.3em;
  -moz-border-radius-bottomright: 0.3em;
  -moz-border-radius-bottomleft: 0.3em;
  border-bottom-right-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
}

.table td {
  vertical-align: middle;
}

.table .show-more-column {
  float: none !important;
  width: 16px;

  scrm-record-details-popup-button {
    display: flex;
    width: 100%;

    scrm-popup-button {
      display: flex;
      width: 100%;

      scrm-button {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}

.list-view-tableactions {
  background-color: $cool-blue;
  padding: 0.2em;
}

.list-view-table thead {
  background-color: $nepal-grey;
  color: $white;
}

.list-view-table td {
  color: $midnight-blue;
  border: none;
}

.bulk-action {
  color: $white;
}

.list-view-table a {
  color: $burnt-red;
  font-weight: bold;
}

.pipeline-chart,
.donut-chart {
  margin: 0 0.5em;
}

.pipeline-chart-content,
.donut-chart-content {
  margin: 1.5em 0;
}

.chart-create {
  margin: 1em 0;
}

@media all and (max-width: $breakpoint-xlarge) {
  .list-view-widget {
    margin: 1.5em 0;
  }
}

@media all and (max-width: $breakpoint-large) {
  table {
    table-layout: fixed;
  }
  td {
    word-wrap: break-word;
  }

  .responsive-table-row th {
    margin: 0;
    padding: 1em 1em 0 1em;
  }

  .responsive-table-row td {
    margin: 0;
    padding: 0 1em 1em 1em;
  }

  .responsive-table td {
    border: none;
  }

  .hide-pagination-count {
    display: none;
  }

  .responsive-table-row th,
  .responsive-table-row td {
    word-wrap: break-word;
  }

  .list-view-table th,
  .list-view-table td {
    word-wrap: break-word;
  }

  .hide-table-header,
  .hide-table-rows {
    display: none;
  }

  .show-collapsed-header,
  .show-collapsed-rows {
    display: none;
  }

  .expand-table-row {
    margin: 0 2em;
    cursor: pointer;
  }

  .table-striped .responsive-top-row,
  .table-striped tbody tr:nth-of-type(odd).responsive-top-row {
    background-color: $sky-blue;
    -webkit-border-top-left-radius: 0.3em;
    -webkit-border-top-right-radius: 0.3em;
    -moz-border-radius-topleft: 0.3em;
    -moz-border-radius-topright: 0.3em;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
  }

  .responsive-top-row a {
    color: $white;
  }

  .first-table-data {
    background-color: $sky-blue;
  }

  .responsive-table-row td,
  .responsive-table-row th {
    background-color: #edecf0;
    color: $midnight-blue;
  }

  .responsive-table th {
    background-image: linear-gradient(to right, $midnight-grey 10%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 5px 1px;
    background-repeat: repeat-x;
  }

  tr.responsive-table-row {
    background-color: #edecf0;
  }

  .primary-table-header {
    background-color: $nepal-grey;
    color: $white;
  }

  .list-view-table thead th {
    border: none;
  }

  .display-block-table th,
  .display-block-table td {
    display: block;
    width: 100%;
  }

  .responsive-table-toggler {
    text-align: right;
    width: 100%;
    cursor: pointer;
  }

  .table td {
    border: none;
  }

  td.list-group-icon {
    margin: 0;
    padding: 0.75rem 0;
    display: inline;
  }
  .list-view-title {
    margin: 0.5em 1em;
  }

  .list-view-table .row {
    margin-left: 1em;
    margin-right: 1em;
  }

  .list-view-header .row {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0.9em;
    margin-right: 0.9em;
  }

  .list-view-header .col {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.primary-table-header {
  background-color: $nepal-grey;
  color: $white;
  white-space: nowrap;
}

.table th {
  border-top: 0;
}

.table thead th {
  border-bottom: 0;
}

.primary-table-header span {
  font-weight: bold;
}

.primary-table-header span:hover {
  text-decoration: underline;
  cursor: pointer;
}

td.list-group-icon {
  margin: 0;
  padding: 0.75rem 0;
}

.cdk-column-checkbox {
  width: 2.7rem;
}

.responsive-table-row.display-block-table {
  border-left: 0.1em solid #aa9dcc;
  border-right: 0.1em solid #aa9dcc;
  margin: 0 0 0.5em 0;
  -webkit-border-bottom-right-radius: 0.25em;
  -webkit-border-bottom-left-radius: 0.25em;
  -moz-border-radius-bottomright: 0.25em;
  -moz-border-radius-bottomleft: 0.25em;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

@media all and (max-width: $breakpoint-large) {
  .checkbox-container {
    padding: 0 0.25rem;
  }
  th.show-table-rows {
    padding: 0 1rem;
  }
}

  .list-view-hr-container {
    font-size: 0.85em;
    padding-top: 0.4em;
    padding-left: 1.2em;
    padding-right: 1.2em;
  }

.responsive-top-row .responsive-header-link {
  background-image: linear-gradient(to right, $white 30%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 5px 1px;
  background-repeat: repeat-x;
  text-decoration: none;
}

.display-block-table th:not(:first-child) {
  background-image: linear-gradient(to right, $midnight-grey 10%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 5px 1px;
  background-repeat: repeat-x;
}

.display-block-table td,
.display-block-table th {
  border: none;
}

.primary-table-data {
  width: 100%;
}

td {
  word-wrap: break-word;
}

.select-action-group {
  margin: 0 0 0 0.8em;
  color: $white;
}

.load-more {
  background-color: #76858f;
  background-repeat: no-repeat;
  border: 0.09em solid rgb(233, 233, 233);
  border-image-source: initial;
  border-image-slice: initial;
  border-image-repeat: initial;
  border-radius: 0.15em;
  cursor: pointer;
  overflow: hidden;
  outline: initial none initial;
  padding: 0.3em 0.5em;
  font-weight: 700;
  font-size: .9em;
  color: #fff;
  min-width: 11em;
}

@media all and (max-width: $breakpoint-medium) {
  .select-action-group {
    margin: 0 0 0 1em;
  }
}

.select-action-group .dropdown-toggle::after,
.bulk-action-group,
.bulk-action-group .dropdown-toggle::after {
  color: $white;
}

@media all and (min-width: $breakpoint-medium) {
  .bulk-action {
    margin-left: 0.5em;
  }
}

.pagination-count {
  margin: 0 0.3em;
  font-weight: bold;
  position: relative;
  bottom: 0.1em;
}

.centre-pagination {
  margin: 0 0.7em;
  font-weight: bold;
  position: relative;
  top: initial;
  bottom: initial;
  line-height: 32px;
}

.list-view .col-lg-12, .list-view .col-lg-9, .list-view .col-lg-3 {
  transition-timing-function: ease;
  /* Quick on the way out */
  transition: 0.5s;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
